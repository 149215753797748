import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Advance Directive",
      content: (
        <>
          <p>
            An advance directive is a legal document that states a person’s
            health care wishes should they be unable to communicate.
          </p>
        </>
      ),
    },
    {
      title: "Durable Power of Attorney",
      content: (
        <>
          <p>
            A durable power of attorney is one that becomes effective upon
            determining that the principal is incapacitated or mentally unable
            to handle his or her financial affairs. The terms of the durable
            power of attorney may be broad enough to give an agent the same
            authority as the principal to make decisions about the principal's
            financial affairs.
          </p>
        </>
      ),
    },
    {
      title: "Limited Power of Attorney",
      content: (
        <>
          <p>
            A limited power of attorney gives you the opportunity to specify
            exactly what you are comfortable with an agent handling on your
            behalf.
          </p>
        </>
      ),
    },

  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Agent Representation San Diego | Brierton, Jones & Jones"
        description="Do you need agent representation in San Diego? Brierton, Jones & Jones, LLP connects you with what you need. Schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  UNDERSTAND YOUR ROLE
                </div>
                <h1>San Diego Agent Representation</h1>
                <p>
                  At At Brierton, Jones & Jones, LLP, we represent agents under
                  health care directives (a health care proxy) and powers of
                  attorney to help them understand their duties and powers.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/13 Agent Representation/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>Health Care Proxy vs. Power of Attorney</h2>
                <p>
                  A <b>health care proxy</b> is a legal document that gives an
                  agent the authority to make medical decisions on another’s
                  behalf should they be unable to speak for themselves (it can
                  be immediately effective).
                </p>
                <p>
                  A health care proxy differs from a living will, which
                  documents your health care wishes, but does not appoint
                  someone to communicate those wishes on your behalf. Living
                  wills are directed at unnamed family or friends, and hospitals
                  and medical facilities.
                </p>
                <p>
                  A <b>power of attorney</b> is a legal document that gives an
                  agent the authority to handle financial matters on another’s
                  behalf for non-trust assets (such as retirement accounts,
                  financial accounts, life insurance, and tax returns).
                </p>
                <p>
                  A person can choose one or more agents to serve jointly or in
                  succession in either or both capacities. There are also
                  options to identify backups.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>How Do We Assist Agents?</h2>
                <p>We counsel agents on:</p>
                <ul className="mb-6 list-disc pl-6">
                  <li>Whether they have the current authority to act</li>
                  <li>
                    Whether an <Link to="/trust-accountings/">accounting</Link>{" "}
                    is required
                  </li>
                  <li>
                    Whether the power of attorney must be recorded in the
                    relevant county real property records
                  </li>
                </ul>
                <p>
                  In some cases, we may analyze whether a{" "}
                  <Link to="/conservatorship-lawyer/">conservatorship</Link> is
                  needed based on the contents of the power of attorney and the
                  extent of its authority.
                </p>
                <p>
                  We may also assist in enabling communication between the
                  agent, financial and taxing authorities, or medical persons or
                  institutions, and facilitating acceptance of the respective
                  power of attorney or health care directory by third parties.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>Here are the some of the terms that agents should know:</p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={12} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="If you’re in need of agent representation, our San Diego attorneys can help you out and answer all your questions. Contact us today." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/13 Agent Representation.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/13 Agent Representation.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
